'use strict';

angular.module('enervexSalesappApp').controller('UserCtrl', function($scope, $state, User, $stateParams, Flash, Account, _, Auth, UserRole) {
	$scope.errors = {};
	$scope.userId = $stateParams.id;
	$scope.roles = ['owner', 'member'];
	$scope.statuses = ['pending', 'active', 'locked'];
	$scope.userRoles = UserRole.query();
	$scope.offset = 0;
	$scope.limit = 100;
	$scope.ctx = {}

	$scope.userRoleDescriptions= function() {
		var strings = _.map($scope.userRoles, function(role){
			return "&quot;"+role.name + "&quot; "+role.description
		})
		return strings.join("<br>")
	}
	$scope.allAccounts = [];
	if ($stateParams.id == "new"){
		$scope.user = {}
	} else {
		User.get({
			id: $stateParams.id
		}).$promise.then(function(user) {
			$scope.user = user;
			$scope.user.role = $scope.user.role && $scope.user.role._id;
		});
		fetchAccounts()
		$scope.newItem = {
			role: "owner"
		}
	}
	$scope.refetchAccounts = function() {
		$scope.allAccounts = $scope.accounts = [];
		$scope.offset = 0;
		fetchAccounts()
	}
	function fetchAccounts() {
		Account.userMember({
			userId: $stateParams.id,
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(accounts){
			if (accounts.length > 0) {
				accounts = _.map(accounts, function(account){
					account.membership = _.find(account.members, function(member){
						return member.user == $stateParams.id
					})
					return account;
				})
				$scope.allAccounts = $scope.allAccounts.concat(accounts);
				$scope.offset = $scope.offset + $scope.limit;
				fetchAccounts();
			} else {
				$scope.accounts = $scope.allAccounts;
			}
		});
	}
	$scope.addToAllOwner = function() {
		Account.addToAllOwner({
			userId: $stateParams.id
		}).$promise.then(function(result) {
			
			$scope.success = true;
			Flash.create('success', '<strong>Success!</strong>' + result.message , 0, {}, true);
			$scope.refetchAccounts()
		}).catch(function(err){
			
			$scope.errors = {};
			$scope.errors.other = 'Problem adding';
			var message = (err.data && err.data.message) || "There was an error adding the user";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		})
	}
	$scope.removeMemberFromAll = function() {
		Account.removeMemberFromAll({
			userId: $stateParams.id
		}).$promise.then(function(result) {
			
			$scope.success = true;
			Flash.create('success', '<strong>Success!</strong>' + result.message , 0, {}, true);
			$scope.refetchAccounts()
		}).catch(function(err){
			
			$scope.errors = {};
			$scope.errors.other = 'Problem adding';
			var message = (err.data && err.data.message) || "There was an error removing the user";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		})
	}
	$scope.save = function(form){
		if (form.$valid){
			var payload = _.clone($scope.user);
			payload = _.omit(payload, "__v");
			if (payload._id) {
				User.update({
					id: payload._id
				}, payload).$promise.then(function(result){
					
					$scope.success = true;
					// $scope.user = result;
					Flash.create('success', '<strong>Success!</strong> Successfully saved user.', 0, {}, true);
				}).catch(function(err){
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving the user";
					Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
				});
			} else {
				payload.dontConfirm = true;
				User.save(payload).$promise.then(function(result){
					
					$scope.success = true;
					$scope.user = result;
					Flash.create('success', '<strong>Success!</strong> Successfully saved user.', 0, {}, true);
					$state.go('user', {
						id: $scope.user._id
					}, {
						reload: true,
						inherit: false
					})
				}).catch(function(err){
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving the user";
					Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
				});
			}
		}
	}
	$scope.updateRole = function(account){
		var member = account.membership;
		console.log("updating role to "+member.role + " for "+ member.user.email)
		Account.update({
			id: account._id,
		}, {
			members: account.members
		}).$promise.then(function(account){
			console.log('succssfully updated account')
		}).catch(function(err){
			console.log('failed to update account')
		});
	}
	$scope.removeMember = function(account){
		var member = account.membership;
		Account.removeMember({
			id: account._id,
			userId: $scope.userId
		}).$promise.then(function(res){
			$scope.accounts = _.filter($scope.accounts, function(a){
				return a != account;
			})
		})
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		data: function (term) {
			return Account.query({
				limit:20,
				term: term
			}).$promise.then(function(accounts){
				$scope.accountsPage = accounts;
				return _.map(accounts, function(account){
					return account.prefixCode + " - " + account.name
				});
			})
		},
		itemSelected: function(item){
			try {
				var account = _.find($scope.accountsPage, function(account){
					return (account.prefixCode + " - " + account.name) == item.item
				})
				console.log("found account", account)
				$scope.newItem.account = account;				
			}catch(e){
				console.log(e)
			}
		}
	}
	$scope.addMembership = function() {
		var membership = {
			user: $scope.userId,
			role: $scope.newItem.role
		}
		var account = $scope.newItem.account;
		account.members = _.filter(account.members, function(member){
			return member.user != $scope.userId
		}) || [];
		account.members.push(membership)
		Account.update({
			id: account._id,
		}, {
			members: account.members
		}).$promise.then(function(account){
			$scope.newItem = {
				role: "owner"
			}
			fetchAccounts()
			console.log('succssfully updated account')
		}).catch(function(err){
			console.log('failed to update account')
		});
	}
	$scope.resendInvite = function() {
		Flash.clear()
		User.resendInvite({
			id: $scope.user._id
		},{}).$promise.then(function(res){
			
			$scope.success = true;
			var message = '<strong>Success!</strong> '+res.message;
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err){
			Flash.clear()
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = (err.data && err.data.message) || "There was an error sending password";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		});
	}
	$scope.resendLogin = function() {
		Flash.clear()
		Auth.resendLogin($scope.user, $scope.ctx.password).then(function(res){
			
			$scope.success = true;
			var message = '<strong>Success!</strong> '+res.message;
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err){
			Flash.clear()
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = (err.data && err.data.message) || "There was an error sending login";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		});
	}
	$scope.sendPassword = function() {
		Flash.clear()
		Auth.sendPassword($scope.user).then(function(res){
			
			$scope.success = true;
			var message = '<strong>Success!</strong> '+res.message;
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err){
			Flash.clear()
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = (err.data && err.data.message) || "There was an error sending password";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		});
	}
});
